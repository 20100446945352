import { CustomHeaderInput } from "@/components/AdvancedEditor/plugins/OutlinePlugin/Outline";
import {
  Button,
  Combobox,
  Input,
  Popover,
  PopoverContent,
  PopoverMenuGroup,
  PopoverMenuItem,
  PopoverTrigger,
  Spinner,
  Tag,
} from "@/components/Elements";
import { cn } from "@/utils/style";
import { PlusIcon } from "@heroicons/react/24/solid";
import { GripVerticalIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import {
  TbBoxAlignBottomFilled,
  TbBoxAlignTopFilled,
  TbCircleFilled,
  TbDots,
  TbHeading,
  TbPencil,
  TbSparkles,
  TbTrash,
} from "react-icons/tb";

export const valueColorMap = {
  h1: "emerald",
  h2: "sky",
  h3: "amber",
  h4: "rose",
  h5: "zinc",
};

export const headerMarginMap = {
  h1: "ml-0",
  h2: "ml-0",
  h3: "ml-[20px]",
  h4: "ml-[40px]",
  h5: "ml-[60px]",
};

export const HeaderMenu = ({
  isOpen,
  setIsOpen,
  headerId,
  handleUpdateHeaderType,
  headerText,
  headerType = "h1",
  color,
  isClickable = true,
}) => {
  const headerOptions = [
    {
      label: "H2",
      value: "h2",
      onClick: () => handleUpdateHeaderType(headerId, "h2"),
    },
    {
      label: "H3",
      value: "h3",
      onClick: () => handleUpdateHeaderType(headerId, "h3"),
    },
    {
      label: "H4",
      value: "h4",
      onClick: () => handleUpdateHeaderType(headerId, "h4"),
    },
  ];

  if (!isClickable) {
    return <HeaderTag headerType={headerType} color={color} />;
  }

  return (
    <Combobox
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      options={headerOptions}
      buttonVariant="buttonNode"
      buttonClassName="rounded-full"
      className="shrink-0"
      value={headerType}
      showEndIcon={false}
      trigger={<HeaderTag headerType={headerType} color={color} />}
    />
  );
};

export function HeaderTag({
  headerType,
  color,
}: {
  headerType: string;
  color: string;
}) {
  return (
    <Tag variant="smallButton" color={color}>
      {headerType.toUpperCase()}
    </Tag>
  );
}

function GenerateInstructionsButton({ onClick }) {
  return (
    <Button
      variant="buttonNodeIcon"
      className={cn(
        " hover:bg-zinc-200 text-emerald-600 hover:text-emerald-700 invisible group-hover:visible shrink-0"
      )}
      textClassName="text-emerald-600 dark:text-emerald-400"
      buttonIcon={
        <TbSparkles
          className="text-emerald-600 hover:text-emerald-700 w-4 h-4 mr-2"
          style={{
            fill: "#059669",
            strokeColor: "#059669",
          }}
        />
      }
      tooltipContent={"Generate instructions for heading"}
      onClick={onClick}
    />
  );
}

function DeleteAssetButton({ onClick }) {
  return (
    <Button
      variant="buttonIcon"
      className={cn(
        " hover:bg-zinc-200 invisible group-hover:visible shrink-0"
      )}
      buttonIcon={<TbTrash />}
      tooltipContent={"Delete header"}
      onClick={onClick}
    />
  );
}

export function AssetMenu({
  handleUpdateHeaderType,
  handleDeleteHeader,
  handleRenameHeader,
  handleInsertNewHeaderAbove,
  handleInsertNewHeaderBelow,
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="buttonIcon"
          size="sm"
          className={cn(
            " group-hover:visible invisible flex-shrink-0",
            popoverOpen && "visible"
          )}
          tooltipContent={"Header options"}
          buttonIcon={<TbDots />}
        />
      </PopoverTrigger>
      <PopoverContent sideOffset={5}>
        <PopoverMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PopoverMenuItem
            onClick={() => {
              handleInsertNewHeaderAbove();
              setPopoverOpen(false);
            }}
          >
            <TbBoxAlignTopFilled className="mr-2" />
            Add heading above
          </PopoverMenuItem>
          <PopoverMenuItem
            onClick={() => {
              handleInsertNewHeaderBelow();
              setPopoverOpen(false);
            }}
          >
            <TbBoxAlignBottomFilled className="mr-2" />
            Add heading below
          </PopoverMenuItem>
          <PopoverMenuItem
            onClick={() => {
              handleRenameHeader(true);
              setPopoverOpen(false);
            }}
          >
            <TbPencil className="mr-2" />
            Edit heading name
          </PopoverMenuItem>
          <PopoverMenuItem
            onClick={() => {
              handleUpdateHeaderType();
              setPopoverOpen(false);
            }}
          >
            <TbHeading className="mr-2" />
            Change type
          </PopoverMenuItem>
          <PopoverMenuItem
            onClick={() => {
              handleDeleteHeader();
              setPopoverOpen(false);
            }}
            className="text-red-500 dark:text-red-500"
          >
            <TbTrash className="mr-2" />
            Delete heading
          </PopoverMenuItem>
        </PopoverMenuGroup>
      </PopoverContent>
    </Popover>
  );
}

export const HeaderInput = ({
  headerId,
  headerText,
  handleRenameHeader,
  handleDeleteHeader,
  setIsRenamingHeader,
  isRenamingHeader,
}) => {
  const [value, setValue] = useState(headerText);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isRenamingHeader]);

  return (
    <Input
      ref={inputRef}
      className="text-zinc-900 dark:text-white text-xs font-medium mx-2 z-50"
      containerClassName="flex w-full"
      value={value}
      onChange={(value) => setValue(value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();

          if (value === "") {
            handleDeleteHeader(headerId);
          } else {
            handleRenameHeader(headerId, value);
          }
          setIsRenamingHeader(false);
          setValue("");
        }
      }}
      onBlur={(event) => {
        if (event.target.id === "header-type-combobox") {
          return;
        }

        if (value === "") {
          handleDeleteHeader(headerId);
        } else {
          handleRenameHeader(headerId, value);
        }
        setIsRenamingHeader(false);
        setValue("");
      }}
    />
  );
};

export const OutlineAsset = React.forwardRef(
  (
    {
      innerRef,
      index,
      headerText,
      headerType = "h1",
      headerId,
      assetText,
      url,
      onClick,
      color = valueColorMap[headerType.toLowerCase()] ?? "emerald",
      isDragging,
      draggableStyle,
      placeholder,
      handleUpdateHeaderType,
      handleDeleteHeader,
      handleRenameHeader,
      handleInsertNewHeader,
      setCustomHeaderInputVisible,
      setCustomHeaderIndex,
      setCustomHeaderValue,
      handleHeaderChange,
      selectedHeader = "h1",
      customHeaderValue,
      setIsIndexInsert,
      setActiveIndex,
      activeIndex,
      setSelectedHeader,
      isTitleAsset,
      showDeleteButton = true,
      showAssetMenu = true,
      showInstructions = true,
      showAddInstructionButton = true,
      handleAddInstruction,
      handleUpdateInstruction,
      instruction,
      loadingInstructions,
      isGeneratingInstructions,
      handleGenerateInstructionForHeading,
      header,
      globalInstructions,
      handleUpdateGlobalInstruction,
      ...rest
    },
    ref
  ) => {
    const headerIndex = parseInt(index, 10);
    const [isHeaderTypeDropdownOpen, setIsHeaderTypeDropdownOpen] =
      useState(false);
    const [isRenamingHeader, setIsRenamingHeader] = useState(false);
    const [insertBelow, setInsertBelow] = useState(false);
    const [insertAbove, setInsertAbove] = useState(false);
    const [showArticleInstructions, setShowArticleInstructions] = useState(
      globalInstructions && globalInstructions.length > 0 ? true : false
    );
    const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
    const [instructions, setInstructions] = useState(() => {
      try {
        return instruction ? JSON.parse(instruction) : [];
      } catch (error) {
        return [];
      }
    });
    const [articleInstructions, setArticleInstructions] =
      useState(globalInstructions);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (spanRef.current) {
        // You can replace 100 with whatever width threshold you need
        setIsAbsolute(spanRef.current.offsetWidth > 100);
      }
    }, [headerText]);

    useEffect(() => {
      if (instruction) {
        try {
          setInstructions(JSON.parse(instruction));
        } catch (error) {
          setInstructions([]);
        }
      } else {
        setInstructions([]);
      }
    }, [instruction]);

    useEffect(() => {
      if (globalInstructions) {
        setArticleInstructions(globalInstructions);
      } else {
        setArticleInstructions([]);
      }
    }, [globalInstructions]);

    useEffect(() => {
      if (activeIndex !== headerIndex) {
        setInsertAbove(false);
        setInsertBelow(false);
      }
    }, [activeIndex]);

    return (
      <div
        draggable
        ref={innerRef}
        {...rest}
        className={cn("flex flex-col items-start w-full group", draggableStyle)}
      >
        {insertAbove && (
          <CustomHeaderInput
            containerClassName="mb-2"
            customHeaderInputVisible={insertAbove}
            setCustomHeaderInputVisible={setInsertAbove}
            selectedHeader={selectedHeader}
            handleHeaderChange={handleHeaderChange}
            setCustomHeaderValue={setCustomHeaderValue}
            hidden={false}
            setIsIndexInsert={setIsIndexInsert}
            customHeaderValue={customHeaderValue}
            handleAddCustomHeader={() => {
              handleInsertNewHeader(
                customHeaderValue,
                selectedHeader,
                headerIndex
              );
            }}
          />
        )}
        <div className="flex items-start w-full">
          {!isTitleAsset && (
            <div className="flex px-0 py-0.5 mt-2 ml-0 mr-1 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-md flex-shrink-0 group-hover:visible invisible">
              <GripVerticalIcon className="h-4 w-4 text-zinc-500" />
            </div>
          )}
          <div
            className={cn(
              "w-full ml-2 px-2 py-2 text-2xs bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5",
              headerMarginMap[headerType]
            )}
          >
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col items-start space-y-1.5 w-full">
                <div className="flex group relative items-start justify-start w-full text-start text-zinc-900">
                  <HeaderMenu
                    isOpen={isHeaderTypeDropdownOpen}
                    setIsOpen={setIsHeaderTypeDropdownOpen}
                    handleUpdateHeaderType={(headerId, headerType) => {
                      setIsHeaderTypeDropdownOpen(false);
                      handleUpdateHeaderType(headerId, headerType);
                    }}
                    headerId={headerId}
                    headerType={headerType}
                    headerText={headerText}
                    color={color}
                    isClickable={!isTitleAsset}
                  />
                  {isRenamingHeader ? (
                    <HeaderInput
                      headerId={headerId}
                      headerText={headerText}
                      handleRenameHeader={handleRenameHeader}
                      handleDeleteHeader={handleDeleteHeader}
                      setIsRenamingHeader={setIsRenamingHeader}
                      isRenamingHeader={isRenamingHeader}
                    />
                  ) : (
                    <span
                      className={cn(
                        "dark:text-zinc-50 ml-2 self-center text-sm font-medium rounded-md px-2 py-0.5 w-full",
                        !isTitleAsset &&
                          " hover:bg-zinc-100 dark:hover:bg-zinc-700 hover:cursor-text"
                      )}
                      onClick={() => {
                        if (isTitleAsset) {
                          return;
                        }
                        setIsRenamingHeader(true);
                      }}
                    >
                      {headerText}
                    </span>
                  )}
                  <div className="absolute right-0 flex items-center space-x-2 group-hover:bg-white/90 group-hover:pl-1.5 group-hover:pb-1.5 group-hover:rounded-bl-md">
                    {!isTitleAsset && showInstructions && (
                      <GenerateInstructionsButton
                        onClick={() =>
                          handleGenerateInstructionForHeading(headerId)
                        }
                      />
                    )}
                    {showDeleteButton && (
                      <DeleteAssetButton
                        onClick={() => handleDeleteHeader(headerId)}
                      />
                    )}
                    {showAssetMenu && (
                      <AssetMenu
                        handleUpdateHeaderType={() =>
                          setIsHeaderTypeDropdownOpen(true)
                        }
                        handleDeleteHeader={() => handleDeleteHeader(headerId)}
                        handleRenameHeader={() => setIsRenamingHeader(true)}
                        handleInsertNewHeaderAbove={() => {
                          setCustomHeaderIndex(
                            headerIndex === 0
                              ? headerIndex
                              : headerIndex === 1
                              ? headerIndex
                              : headerIndex + 1
                          );
                          setInsertAbove(true);
                          setIsIndexInsert(true);
                          setActiveIndex(headerIndex);
                          setSelectedHeader(
                            headerType === "h2" ? "h2" : headerType
                          );
                        }}
                        handleInsertNewHeaderBelow={() => {
                          setCustomHeaderIndex(
                            headerIndex === 0
                              ? headerIndex + 1
                              : headerIndex === 1
                              ? headerIndex + 1
                              : headerIndex + 2
                          );
                          setInsertBelow(true);
                          setIsIndexInsert(true);
                          setActiveIndex(headerIndex);
                          setSelectedHeader(
                            headerType === "h2" ? "h2" : headerType
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
                {loadingInstructions &&
                  loadingInstructions.length > headerIndex &&
                  loadingInstructions[headerIndex] && (
                    <div
                      className="flex items-center w-full space-x-2"
                      autoFocus
                      tabIndex={-1}
                    >
                      <Spinner size="sm" className="ml-12 my-1" />
                      <p className="text-xs text-zinc-600 dark:text-zinc-400 font-medium">
                        Generating instructions...
                      </p>
                    </div>
                  )}
                {showInstructions &&
                  loadingInstructions &&
                  !loadingInstructions[headerIndex] &&
                  instructions.map((instruction, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-start w-full relative"
                    >
                      <div className="flex items-start w-full group/instruction">
                        <div className="flex items-start w-[46px]">
                          {instruction?.length > 0 && (
                            <TbCircleFilled className="text-zinc-600 dark:text-zinc-400 h-1.5 w-1.5 ml-auto mr-1.5 mt-[13px]" />
                          )}
                        </div>
                        <TextareaAutosize
                          className="w-full h-auto resize-none rounded-md bg-white text-zinc-900 hover:cursor-text focus:cursor-text hover:bg-zinc-100 focus:bg-transparent dark:hover:bg-zinc-800 dark:bg-zinc-800 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 lg:flex focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset pl-2.5 py-1 text-xs"
                          placeholder="Enter instruction..."
                          value={instruction}
                          autoFocus={
                            index === instructions.length - 1 &&
                            !isGeneratingInstructions &&
                            shouldAutoFocus
                          }
                          onBlur={(event) => {
                            if (event.target.id === "header-type-combobox") {
                              return;
                            }

                            if (instruction === "") {
                              const newInstructions = instructions.filter(
                                (_, i) => i !== index
                              );
                              setInstructions(newInstructions);
                              handleUpdateInstruction(
                                headerId,
                                newInstructions
                              );
                            } else {
                              handleUpdateInstruction(headerId, instructions);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              //e.preventDefault();

                              const updatedInstructions = [...instructions];
                              updatedInstructions[index] =
                                e.target.value.trim();

                              if (updatedInstructions[index] === "") {
                                updatedInstructions.splice(index, 1);
                              } else if (
                                index === instructions.length - 1 &&
                                updatedInstructions[index] !== ""
                              ) {
                                updatedInstructions.push("");
                              }

                              setInstructions(updatedInstructions);
                              handleUpdateInstruction(
                                headerId,
                                updatedInstructions
                              );
                            }
                          }}
                          onChange={(e) => {
                            setInstructions((prevInstructions) => {
                              const newInstructions = [...prevInstructions];
                              newInstructions[index] = e.target.value;
                              return newInstructions;
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              if (index === instructions.length - 1) {
                                setInstructions([...instructions, ""]);
                              }
                            }
                          }}
                        />
                        <Button
                          variant="buttonIcon"
                          className="absolute top-0.5 right-0.5 group-hover/instruction:visible invisible bg-white dark:bg-zinc-900"
                          buttonIcon={<TbTrash />}
                          tooltipContent={"Delete instruction"}
                          onClick={() => {
                            const newInstructions = instructions.filter(
                              (_, i) => i !== index
                            );
                            setInstructions(newInstructions);
                            handleUpdateInstruction(headerId, newInstructions);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                {!isGeneratingInstructions &&
                  showInstructions &&
                  showAddInstructionButton &&
                  !loadingInstructions[headerIndex] && (
                    <Button
                      variant="textTab"
                      size="2xs"
                      className="text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-500 px-1 ml-[41px] w-fit"
                      textClassName="text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-500 px-1"
                      buttonIcon={<PlusIcon />}
                      onClick={() => {
                        setInstructions([...instructions, ""]);
                        handleAddInstruction(headerId, [""]);
                        setShouldAutoFocus(true);
                      }}
                    >
                      Add instruction
                    </Button>
                  )}
                {!isGeneratingInstructions &&
                  isTitleAsset &&
                  showInstructions &&
                  (articleInstructions || []).map((instruction, idx) => (
                    <div
                      key={headerIndex}
                      className="flex flex-col items-start w-full relative"
                    >
                      <div
                        className="flex items-start w-full group/instruction"
                        key={idx}
                      >
                        <div className="flex items-start w-11">
                          <TbCircleFilled className="text-zinc-600 dark:text-zinc-400 h-1.5 w-1.5 ml-auto mr-1.5 mt-[13px]" />
                        </div>
                        <TextareaAutosize
                          className="w-full h-auto resize-none rounded-md bg-white text-zinc-900 hover:cursor-text focus:cursor-text hover:bg-zinc-100 focus:bg-transparent dark:hover:bg-zinc-800 dark:bg-zinc-800 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 lg:flex focus:[&:not(:focus-visible)]:outline-none focus:outline-none border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset pl-2.5 py-1 text-xs"
                          placeholder="Enter article-level instruction. This will apply to all headings in the article..."
                          value={instruction}
                          autoFocus={
                            idx === articleInstructions.length - 1 &&
                            shouldAutoFocus
                          }
                          onBlur={(event) => {
                            if (event.target.id === "header-type-combobox") {
                              return;
                            }

                            if (instruction === "") {
                              const newInstructions =
                                articleInstructions.filter((_, i) => i !== idx);
                              setArticleInstructions(newInstructions);
                              handleUpdateGlobalInstruction(newInstructions);
                            } else {
                              handleUpdateGlobalInstruction(
                                articleInstructions
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();

                              const updatedInstructions = [
                                ...articleInstructions,
                              ];
                              updatedInstructions[idx] = e.target.value.trim();

                              if (updatedInstructions[idx] === "") {
                                updatedInstructions.splice(idx, 1);
                              } else if (
                                idx === articleInstructions.length - 1 &&
                                updatedInstructions[idx] !== ""
                              ) {
                                updatedInstructions.push("");
                              }

                              setArticleInstructions(updatedInstructions);
                              handleUpdateGlobalInstruction(
                                updatedInstructions
                              );
                            }
                          }}
                          onChange={(e) => {
                            setArticleInstructions((prevInstructions) => {
                              const newInstructions = [...prevInstructions];
                              newInstructions[idx] = e.target.value;
                              return newInstructions;
                            });
                          }}
                        />
                        <Button
                          variant="buttonIcon"
                          className="absolute top-0.5 right-0.5 group-hover/instruction:visible invisible bg-white dark:bg-zinc-900"
                          buttonIcon={<TbTrash />}
                          tooltipContent={"Delete article instruction"}
                          onClick={() => {
                            const newInstructions = articleInstructions.filter(
                              (_, i) => i !== idx
                            );
                            setArticleInstructions(newInstructions);
                            handleUpdateGlobalInstruction(newInstructions);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                {!isGeneratingInstructions &&
                  isTitleAsset &&
                  showInstructions && (
                    <div className="flex items-center w-full">
                      <Button
                        variant="textTab"
                        size="2xs"
                        className="text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-500  ml-[41px] w-fit"
                        textClassName="text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-500 "
                        buttonIcon={<PlusIcon />}
                        onClick={() => {
                          const newInstructions = [...articleInstructions, ""];
                          setArticleInstructions(newInstructions);
                          setShouldAutoFocus(true);
                        }}
                        tooltipContent="Instructions under your H1 apply to all headings of your article."
                      >
                        Add article-level instruction
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            {placeholder}
          </div>
        </div>
        {insertBelow && (
          <CustomHeaderInput
            containerClassName="mt-2"
            customHeaderInputVisible={insertBelow}
            setCustomHeaderInputVisible={setInsertBelow}
            selectedHeader={selectedHeader}
            handleHeaderChange={handleHeaderChange}
            setCustomHeaderValue={setCustomHeaderValue}
            hidden={false}
            setIsIndexInsert={setIsIndexInsert}
            customHeaderValue={customHeaderValue}
            handleAddCustomHeader={() => {
              handleInsertNewHeader(
                customHeaderValue,
                selectedHeader,
                headerIndex + 1
              );
            }}
          />
        )}
      </div>
    );
  }
);
