import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  AvatarStack,
  Button,
  Combobox,
  FilterTag,
  NewDocumentButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableFilter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { useCreateFolder } from "@/features/documents/api/createFolder";
import { getDocument } from "@/features/documents/api/getDocument";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useUpdateFolder } from "@/features/documents/api/updateFolder";
import { DeleteContentButton } from "@/features/documents/components/DeleteContentButton";
import { FolderDropdownMenu, FolderInput } from "@/features/folders";
import { cn, usernameToColor } from "@/utils/style";
import { FolderIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { MoreHorizontal, Plus, TrashIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionsCell } from "./ActionsCell";
import { DocumentRow, TableProps } from "./DocumentTable.types";
import { FolderCell, FolderHeader } from "./FolderCell";
import { SelectCell } from "./SelectCell";
import {
  StatusCell,
  StatusHeader,
  getStatusOptions,
  statusNameToIdMap,
} from "./StatusCell";

import { useSubscription } from "@/features/auth/api/getSubscription";
import { FraseDocument } from "@/features/documents";
import { UnarchiveDocumentButton } from "@/features/documents/components/UnarchiveDocument";
import { useNotificationStore } from "@/stores/notifications";
import { TbMinus } from "react-icons/tb";
import { FraseBot, NewDocumentButtonAction } from "..";
import { TargetDateCell, TargetDateHeader } from "./TargetDateCell";
import { UserCell, UserHeader } from "./UserCell";

export const DocumentTable: React.FC<TableProps> = ({
  documents = [],
  documentsCount,
  folders = [],
  users = [],
  filters = [],
  folderInfo,
  getArchived,
  selectedFilters,
  setSelectedFilters,
  page,
  setSize,
  setPage,
  isDocumentsLoading,
}) => {
  const navigate = useNavigate();
  const [comboboxOpen, setComboboxOpen] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [tableWidth, setTableWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const createFolderMutation = useCreateFolder({});
  const updateFolder = useUpdateFolder();
  const { addNotification } = useNotificationStore();
  const [processedDocuments, setProcessedDocuments] = useState(documents);
  const [processedFolders, setProcessedFolders] = useState(folders);
  const [processedUsers, setProcessedUsers] = useState(users);
  const [renamingFolder, setRenamingFolder] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [showFolderInput, setShowFolderInput] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const { data: subscriptionData } = useSubscription({});

  const isSubscriptionInactive = subscriptionData?.status === "inactive";

  const onFieldUpdate = (
    updates: { field: string; value: any }[],
    data: FraseDocument | FraseDocument[]
  ) => {
    const optimisticUpdate = (
      doc: FraseDocument,
      updates: { field: string; value: any }[]
    ) => {
      return updates.reduce(
        (acc, update) => ({ ...acc, [update.field]: update.value }),
        doc
      );
    };

    const updateDocumentField = async (
      doc: FraseDocument,
      updates: { field: string; value: any }[]
    ) => {
      try {
        const document = await getDocument({
          documentHash: doc.hash,
        });

        const updatedDocument = optimisticUpdate(document, updates);

        await updateDocumentMutation.mutateAsync(updatedDocument);
      } catch (error) {
        console.error("Error updating document:", error);
        addNotification({
          type: "error",
          title: "Document update failed",
          message: `We encountered an issue while updating the document "${doc.metadata?.name}". Please try again.`,
        });
      }
    };

    const handleUpdate = async (doc: FraseDocument) => {
      const optimisticDoc = optimisticUpdate(doc, updates);
      setProcessedDocuments((currentDocs) =>
        currentDocs.map((currentDoc) =>
          currentDoc.id === doc.id ? optimisticDoc : currentDoc
        )
      );
      await updateDocumentField(doc, updates);
    };

    if (Array.isArray(data)) {
      // Optimistically update the state for all selected documents
      data.forEach((doc) => {
        handleUpdate(doc.original);
      });

      // Immediately deselect all rows after optimistic update
      table.toggleAllPageRowsSelected(false);
    } else {
      handleUpdate(data);
      // Immediately deselect the row after optimistic update
      table.toggleAllPageRowsSelected(false);
    }
  };

  // Updated status of the document
  const onStatusClick = async (value: string, data: { original: Document }) => {
    onFieldUpdate(
      [
        { field: "doc_status", value: statusNameToIdMap[value] },
        { field: "doc_status_desc", value: value },
      ],
      data
    );
  };

  useEffect(() => {
    const processedDocuments = documents.map((doc) => {
      return {
        ...doc,
        teamId: doc.teamId?.toString(),
      };
    });

    const processedFolders = folders.map((folder) => {
      return {
        value: folder?.id ? folder?.id?.toString() : null,
        label: folder?.name,
        onClick: (_value: any, data: { original: Document }) => {
          onFieldUpdate([{ field: "teamId", value: folder.id }], data);
        },
        icon: <FolderIcon className="w-3 h-3 mr-2" />,
      };
    });

    const handleCreateFolder = async (
      _value: string,
      data: { hash: string }
    ) => {
      setIsCreatingFolder(true);
      try {
        const document = await getDocument({ documentHash: data.hash });
        const folderResponse = await createFolderMutation.mutateAsync({
          name: _value,
        });
        await updateDocumentMutation.mutateAsync({
          ...document,
          teamId: folderResponse.team.id,
        });
      } catch (error) {
        // Handle the error appropriately here
        console.error("Error creating folder or updating document:", error);
      } finally {
        setIsCreatingFolder(false);
      }
    };

    const newFolderOption = {
      value: "New Folder",
      label: "New Folder",
      isLoading: isCreatingFolder,
      onClick: (_value: any, data: { original: FraseDocument }) => {
        handleCreateFolder(_value, data);
      },
      icon: <Plus className="w-3 h-3 mr-2" />,
      type: "input",
    };
    processedFolders.unshift(newFolderOption);

    const processedUsers = users.map((user) => {
      return {
        value: user.fullName,
        label: user.fullName,
        onClick: (_value: any, data: { original: Document }) => {
          onFieldUpdate(
            [
              { field: "doc_owner_name", value: user.fullName },
              { field: "doc_owner", value: user.id },
            ],
            data
          );
        },
        icon: (
          <Avatar className="w-5 h-5 mr-2">
            <AvatarImage src="" />
            <AvatarFallback
              className={cn(
                "text-zinc-50 text-4xs",
                usernameToColor(user.fullName)
              )}
            >
              {user.fullName
                .split(" ")
                .map((name) => name[0])
                .slice(0, 2)
                .join("")}
            </AvatarFallback>
          </Avatar>
        ),
      };
    });

    setProcessedDocuments(processedDocuments);
    setProcessedFolders(processedFolders);
    setProcessedUsers(processedUsers);
  }, [documents, folders, users]);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.getBoundingClientRect().width);
      }
    };

    if (tableRef.current) {
      setTableWidth(tableRef.current.getBoundingClientRect().width);
    }

    // Add the event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tableRef]);

  const archivedColumns: ColumnDef<DocumentRow>[] = [
    {
      id: "select",
      meta: { label: "Select" },
      header: SelectCell.Header,
      cell: SelectCell.Cell,
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
    {
      accessorKey: "metadata.name",
      meta: { label: "Name" },
      header: ({ column }) => {
        return <p className="text-xs">Name</p>;
      },
      cell: ({ row }) => {
        let title = row.original.metadata?.name || "Untitled";
        title = title.length > 0 ? title.split(",")[0] : title;
        return <p className="text-xs font-medium dark:text-white">{title}</p>;
      },
      size: 500,
    },
    {
      accessorKey: "query",
      meta: { label: "Document" },
      header: ({ column }) => {
        return <p className="text-xs">Search Query</p>;
      },
      cell: ({ row }) => {
        return row.original.query ? (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {row.original.query}
          </p>
        ) : (
          <TbMinus className="w-4 h-4 text-zinc-400" />
        );
      },
      size: 500,
    },
    {
      accessorKey: "archived",
      meta: { label: "Scheduled Deletion" },
      header: () => <p className="text-xs">Scheduled Deletion</p>,
      cell: ({ row }) => {
        return row.original.archived ? (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {dayjs(row.original.archived_dt)
              .add(30, "days")
              .format("MMM DD, YYYY")}
          </p>
        ) : (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            Not Archived
          </p>
        );
      },
      size: 200,
    },
    {
      accessorKey: "teamId",
      meta: { label: "Folder" },
      header: FolderHeader,
      cell: ({ row }) => (
        <FolderCell document={row.original} folders={processedFolders} />
      ),
      size: 200,
    },
    {
      accessorKey: "doc_owner_name",
      meta: {
        label: "User",
      },
      header: UserHeader,
      cell: ({ row }) => (
        <UserCell
          document={row.original}
          users={processedUsers}
          showName
          showAvatar={false}
        />
      ),
      size: 200,
    },
    {
      accessorKey: "doc_status_desc",
      meta: {
        label: "Status",
      },
      header: StatusHeader,
      cell: ({ row }) => (
        <StatusCell
          document={row.original}
          variant="default"
          onFieldUpdate={onFieldUpdate}
        />
      ),
      size: 200,
    },
    {
      id: "actions",
      meta: { label: "Actions" },
      cell: ({ row }) => (
        <ActionsCell.Cell
          row={row}
          table={table}
          getArchived={getArchived}
          type="document"
        />
      ),
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
  ];

  const columns: ColumnDef<DocumentRow>[] = [
    {
      id: "select",
      meta: { label: "Select" },
      header: SelectCell.Header,
      cell: SelectCell.Cell,
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
    {
      accessorKey: "metadata.name",
      meta: { label: "Name" },
      header: ({ column }) => {
        return <p className="text-xs">Name</p>;
      },
      cell: ({ row }) => {
        let title =
          row.original.metadata?.name || row.original.query || "Untitled";
        title = title.length > 0 ? title.split(",")[0] : title;
        return <p className="text-xs font-medium dark:text-white">{title}</p>;
      },
      size: 500,
    },
    {
      accessorKey: "query",
      meta: { label: "Document" },
      header: ({ column }) => {
        return <p className="text-xs">Search Query</p>;
      },
      cell: ({ row }) => {
        return row.original.query ? (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {row.original.query}
          </p>
        ) : (
          <TbMinus className="w-4 h-4 text-zinc-400" />
        );
      },
      size: 500,
    },
    {
      accessorKey: "lastEdited",
      meta: {
        label: "Edited",
      },
      header: ({ column }) => <p className="text-xs">Edited</p>,
      cell: ({ row }) => {
        return (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {dayjs().to(dayjs(row.getValue("lastEdited")))}
          </p>
        );
      },
      size: 200,
    },
    {
      accessorKey: "teamId",
      meta: { label: "Folder" },
      header: FolderHeader,
      cell: ({ row }) => (
        <FolderCell document={row.original} folders={processedFolders} />
      ),
      size: 200,
    },
    {
      accessorKey: "doc_owner_name",
      meta: {
        label: "User",
      },
      header: UserHeader,
      cell: ({ row }) => (
        <UserCell
          document={row.original}
          users={processedUsers}
          showName
          showAvatar={false}
        />
      ),
      size: 200,
    },
    {
      accessorKey: "doc_status_desc",
      meta: {
        label: "Status",
      },
      header: StatusHeader,
      cell: ({ row }) => (
        <StatusCell
          document={row.original}
          variant="default"
          onFieldUpdate={onFieldUpdate}
        />
      ),
      size: 200,
    },
    {
      accessorKey: "doc_target_date",
      meta: {
        label: "Target Date",
      },
      header: TargetDateHeader,
      cell: ({ row }) => (
        <TargetDateCell document={row.original} onFieldUpdate={onFieldUpdate} />
      ),
      size: 200,
    },
    {
      id: "actions",
      meta: { label: "Actions" },
      cell: ({ row }) => (
        <ActionsCell.Cell row={row} table={table} type={"document"} />
      ),
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
  ];

  const table = useReactTable({
    data: processedDocuments,
    columns: getArchived ? archivedColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const removeFilter = (index: number) => {
    const newFilters = [...selectedFilters];
    newFilters.splice(index, 1);
    setSelectedFilters(newFilters);
  };

  const startRenameFolder = (title, id) => {
    setFolderName(title);
    setRenamingFolder(id);
    setShowFolderInput(true);
  };

  const handleFolderOperation = () => {
    if (folderName !== "") {
      updateFolder.mutate({
        id: renamingFolder,
        name: folderName,
        description: "",
      });
      setRenamingFolder(null);
      setShowFolderInput(false);
    }
  };

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col h-full w-full relative">
        <div className="flex flex-col h-fit">
          <div
            className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800"
            ref={tableRef}
          >
            <div className="flex items-center">
              <Navigation />

              <span className="text-sm font-medium dark:text-white items-center">
                {folderInfo ? (
                  <>
                    {showFolderInput ? (
                      <FolderInput
                        setShowInput={setShowFolderInput}
                        setFolderName={setFolderName}
                        folderName={folderName}
                        handleFolderUpdate={handleFolderOperation}
                        className="text-sm font-medium overflow-ellipsis"
                        containerClassName="ml-0 mr-2 w-40"
                      />
                    ) : (
                      <div className="flex items-center mr-2">
                        {folderInfo.name}
                        <FolderDropdownMenu
                          id={folderInfo.id}
                          title={folderInfo.name}
                          onRename={() =>
                            startRenameFolder(folderInfo.name, folderInfo.id)
                          }
                          triggerComponent={
                            <Button
                              className="ml-2"
                              variant="buttonIcon"
                              buttonIcon={<MoreHorizontal />}
                            />
                          }
                        ></FolderDropdownMenu>
                      </div>
                    )}
                  </>
                ) : (
                  <span className="flex items-center mr-4 [(:first-child)]:ml-1">
                    {getArchived ? (
                      <>Archive</>
                    ) : (
                      <>
                        {table.getIsSomeRowsSelected() ? (
                          <>
                            {table.getFilteredSelectedRowModel().rows.length}{" "}
                            documents selected
                          </>
                        ) : (
                          <>All documents</>
                        )}
                      </>
                    )}
                  </span>
                )}
              </span>
              {folderInfo && (
                <AvatarStack users={users} folderInfo={folderInfo} />
              )}
              {!table.getIsSomeRowsSelected() &&
                (table.getRowModel().rows.length > 0 ||
                  selectedFilters.length > 0) && (
                  <TableFilter
                    setSelectedFilters={setSelectedFilters}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    variant="default"
                    table={table}
                  />
                )}
              {table.getFilteredSelectedRowModel().rows.length > 0 && (
                <>
                  <Combobox
                    data={table.getFilteredSelectedRowModel().rows}
                    options={processedUsers}
                    fixedValue={"Assign user"}
                    showEndIcon={false}
                    enableSearch
                    className="h-7 min-w-fit rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
                  />
                  <Combobox
                    data={table.getFilteredSelectedRowModel().rows}
                    options={processedFolders}
                    fixedValue={"Assign folder"}
                    showEndIcon={false}
                    enableSearch
                    className="h-7 ml-2 min-w-fit rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
                  />
                  <Combobox
                    data={table.getFilteredSelectedRowModel().rows}
                    options={getStatusOptions(onStatusClick)}
                    fixedValue={"Update status"}
                    showEndIcon={false}
                    className="h-7 ml-2 min-w-fit rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
                  />
                  {getArchived ? (
                    <UnarchiveDocumentButton
                      table={table}
                      documentIds={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map((row) => row.original.id),
                      ]}
                      documentTitles={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map(
                            (row) =>
                              row.original.metadata?.name || row.original.query
                          ),
                      ]}
                    />
                  ) : (
                    <DeleteContentButton
                      table={table}
                      entityIds={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map((row) => row.original.id),
                      ]}
                      entityTitles={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map(
                            (row) =>
                              row.original.metadata?.name || row.original.query
                          ),
                      ]}
                      entityType="document"
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex">
              <NewDocumentButton
                trigger={
                  <NewDocumentButtonAction
                    className="w-30"
                    buttonVariant={"primaryBlur"}
                  />
                }
              />
            </div>
          </div>
          {selectedFilters.length > 0 && (
            <div className="border-b border-zinc-200/80 dark:border-white/10 pl-5 flex items-center space-x-2 h-14">
              {selectedFilters.map((filter, idx) => (
                <FilterTag
                  key={filter.value}
                  variant="small"
                  color="zinc"
                  startIcon={filter.icon}
                  onClose={(e) => {
                    const column = table.getColumn(filter.parent);
                    column.setFilterValue(undefined);
                    removeFilter(idx);
                    e.stopPropagation();
                  }}
                >
                  {filter.label}
                </FilterTag>
              ))}
              <TableFilter
                setSelectedFilters={setSelectedFilters}
                filters={filters}
                selectedFilters={selectedFilters}
                table={table}
                variant="plus"
              />
            </div>
          )}
        </div>

        {table.getRowModel().rows.length === 0 && selectedFilters.length > 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="text-md font-base text-zinc-600 dark:text-zinc-400">
              No documents found
            </p>
            <Button
              className="mt-4"
              variant="outlineBlur"
              startIcon={<TrashIcon />}
              size="2xs"
              onClick={() => setSelectedFilters([])}
            >
              Clear Filters
            </Button>
          </div>
        ) : table.getRowModel().rows?.length || selectedFilters.length > 0 ? (
          <div
            className={cn(
              "flex w-full overflow-auto pb-20",
              selectedFilters.length > 0
                ? "max-h-[calc(100vh-112px)]"
                : "max-h-[calc(100vh-105px)]"
            )}
          >
            <Table className="w-full">
              <TableHeader
                className="sticky top-0 z-[9] bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8
            dark:bg-zinc-900 dark:text-white"
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          style={{
                            boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                            width: header.getSize(),
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody className="overflow-y-scroll">
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.original.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                    )}
                    onClick={() => {
                      if (!comboboxOpen) {
                        navigate(`/app/documents/${row.original.hash}`);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
            {getArchived ? (
              <div className="bg-white dark:bg-zinc-800 rounded-md p-12 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
                <FraseBot />
                <div className="space-y-2">
                  <h1 className="text-lg font-medium dark:text-white">
                    No archived documents
                  </h1>
                  <p className="text-base font-normal text-zinc-600 dark:text-zinc-200">
                    Deleted documents will be archived for 30 days, after which
                    they will be permanently deleted.
                  </p>
                </div>
              </div>
            ) : (
              <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
                <FraseBot />
                {folderInfo ? (
                  <div className="space-y-2">
                    <h1 className="text-lg font-medium dark:text-white">
                      No documents in this folder
                    </h1>
                    <p className="text-base font-normal text-zinc-600 dark:text-zinc-200">
                      Use Frase folders to organize your documents, and invite
                      collaborators.
                    </p>
                    <div>
                      <NewDocumentButton
                        trigger={
                          <NewDocumentButtonAction
                            size="sm"
                            className="w-30"
                            startIcon={<></>}
                            buttonVariant="primaryBlur"
                            text={"Create new document"}
                          />
                        }
                      />
                    </div>
                  </div>
                ) : (
                  !isDocumentsLoading &&
                  documents.length === 0 && (
                    <div className="space-y-4">
                      <h1 className="text-lg font-medium dark:text-white">
                        Welcome to Frase Documents!
                      </h1>
                      <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                        Documents are the building blocks of your content. Use
                        them to research competitors, create outlines, articles,
                        and more with AI.
                      </p>
                      {isSubscriptionInactive && (
                        <p className="text-[15px] text- font-normal text-zinc-600 dark:text-zinc-200">
                          Your free trial includes unlimited documents and two
                          search queries. Get started by creating your first
                          document.
                        </p>
                      )}
                      <div>
                        <NewDocumentButton
                          trigger={
                            <NewDocumentButtonAction
                              size="sm"
                              className="w-30"
                              startIcon={<></>}
                              buttonVariant="primaryBlur"
                              text={"Create new document"}
                            />
                          }
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        )}
        <div className="absolute bottom-0 bg-white border-t dark:border-t-zinc-800 h-[48px] max-h-[48px] w-full dark:bg-zinc-900">
          <Pagination
            type="document"
            documentsCount={documentsCount}
            currentPage={page}
            itemsPerPage={table.getState().pagination.pageSize}
            totalItems={
              table.getFilteredRowModel().rows.length ||
              table.getRowModel().rows.length
            }
            onPageChange={(index) => {
              setPage(index); // Pass the index directly
              table.setPageIndex(index); // Assuming this should also be just 'index'
            }}
            onItemsPerPageChange={(pageSize) => table.setPageSize(pageSize)}
            table={table}
          />
        </div>
      </div>
    </div>
  );
};
