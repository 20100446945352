export function addCommasToNumber(number: number): string {
  // Remove any existing non-digit characters
  const cleanNumber = number.toString().replace(/\D/g, "");

  // Add commas using regex
  return cleanNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getGSCPlanNickname(
  name: string
): "Solo" | "Team" | "Basic" | "Growth" | "Free" {
  if (name.includes("lifetime")) {
    // We give them a team plan domain limit as they are grandfathered in
    return "Team";
  } else if (name.includes("solo")) {
    return "Solo";
  } else if (name.includes("team")) {
    return "Team";
  } else if (name.includes("basic")) {
    return "Basic";
  } else if (name.includes("answer")) {
    return "Growth";
  } else {
    return "Free";
  }
}
